import { NavLink } from "react-router-dom";
import "./index.scss";
const Card = ({ buttonText, title, icon, backgroundColor, routeTo, }: any) => {

  return (
    <div className="card-container" style={{ backgroundColor }}>
      <img src={icon} alt="card" />
      <h4>{title}</h4> 
      <button >
        <NavLink to={routeTo} >{buttonText}</NavLink>
      </button>
    </div>
  );
};

export default Card;
