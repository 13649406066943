import { useMemo, useState } from "react";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import Button from "react-bootstrap/Button";
import NaijaStates from "naija-state-local-government";
import { DotLoader } from "react-spinners";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import "./index.scss";
import DropDown from "../../shared/components/dropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  addStudentToAdmissionList,
  getMatricNumberFormation,
} from "../../redux/action/admission";
import { handleRequestErrors } from "../../shared/utils";
import InputField from "../../shared/components/InputField";

const StudentModal = ({
  show,
  setShow,
  initialValues,
  studentInfo,
  setStudentInfo,
  session,
  selectedDepartment,
  setSelectedDepartment,
  selectedFaculty,
  setSelectedFaculty,
}: any) => {
  const [matricNumberFormation, setMatricNumberFormation] =
    useState<string>("");
  const dispatch = useDispatch();
  const { faculties, allProgrammes, addNewStudentLoading } = useSelector(
    (state: any) => state.admissionReducer
  );
  const formatFaculties = useMemo(
    () =>
      faculties.map((faculty: any) => {
        return {
          value: faculty.undergraduateFacultyName,
          label: faculty.undergraduateFacultyName,
        };
      }),
    [faculties]
  );

  const programmesList =
    allProgrammes
      .filter(
        (programme: any) => programme.departmentUniqueId === selectedDepartment
      )
      .map((prog: any) => {
        return {
          value: prog.programmeName,
          label: prog.programmeName,
        };
      }) || [];
  const departmentsOptions =
    faculties
      ?.find(
        (faculty: any) => faculty?.undergraduateFacultyName === selectedFaculty
      )
      ?.undergraduateDepartments?.map((dept: any) => {
        return {
          value: dept?.undergraduateDepartmentName,
          label: dept?.undergraduateDepartmentName,
          id: dept?.undergraduateDepartmentUniqueId,
        };
      }) || [];

  const statesInNigeria = useMemo(
    () =>
      NaijaStates.states().map((state: string) => {
        return { value: state, label: state };
      }),
    []
  );

  const getMatricNumber = async (programme: string) => {
    await getMatricNumberFormation(programme)
      .then((res) => {
        setMatricNumberFormation(res.data);
      })
      .catch((err: any) => {
        handleRequestErrors(err);
      });
  };

  let checkValidationSchema = Yup.object().shape({
    registrationNumber: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    fullName: Yup.string().required("Required"),
    lga: Yup.string().required("Required"),
    faculty: Yup.string().required("Required"),
    nationality: Yup.string().required("Required"),
    totalScore: Yup.string().required("Required"),
    department: Yup.string().required("Required"),
    isNotApproved: Yup.boolean().required("Required"),
    notApprovedReason: Yup.string().when("isNotApproved", {
      is: true,
      then: Yup.string().required("Required when not approved"),
      otherwise: Yup.string().nullable(),
    }),
    sex: Yup.string().required("Required"),
    programme: Yup.string().required("Required"),
    levelOfEntry: Yup.string().required("Required"),
    session: Yup.string().required("Required"),
  });
  return (
    <Modal
      show={show}
      onHide={() => {
        setMatricNumberFormation("");
        setShow(false);
        setStudentInfo(initialValues);
      }}
      size="lg"
      contentClassName="student-info-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {studentInfo.studentId
            ? "EDIT STUDENT REGISTRATION DATA"
            : "ADD NEW STUDENT REGISTRATION DATA"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="student-modal-form">
        <Container>
          <Formik
            initialValues={studentInfo}
            validationSchema={checkValidationSchema}
            onSubmit={async (values) => {
              const valuesObj = {
                ...values,
                matricNumberFormation:
                  matricNumberFormation || values.matricNumberFormation,
              };
              await dispatch(
                addStudentToAdmissionList(
                  valuesObj,
                  setShow,
                  setStudentInfo,
                  initialValues,
                  session
                )
              );
              setMatricNumberFormation("");
            }}
          >
            {(props: FormikProps<any>) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                setFieldValue,
                setFieldTouched,
              } = props;
              return (
                <Form>
                  <Row>
                    <Col xs={12} md={6}>
                      <InputField
                        field="registrationNumber"
                        label="Reg. Number"
                        value={values.registrationNumber}
                        touched={touched}
                        errors={errors}
                        handleChange={handleChange}
                        disabled={!!studentInfo.registrationNumber}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <Form>
                        <DropDown
                          label="Nationality"
                          touched={touched}
                          errors={errors}
                          options={[
                            { value: "NIGERIAN", label: "NIGERIA" },
                            { value: "NON-NIGERIA", label: "NON-NIGERIA" },
                          ]}
                          value={{
                            value: values.nationality,
                            label: values.nationality,
                          }}
                          defaultValue={{
                            value: values.nationality,
                            label: values.nationality,
                          }}
                          field="nationality"
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                        />
                      </Form>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={6}>
                      <InputField
                        field="fullName"
                        label="Full Name"
                        value={values.fullName}
                        touched={touched}
                        errors={errors}
                        handleChange={handleChange}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <Form>
                        <DropDown
                          label="State"
                          name="state"
                          touched={touched}
                          errors={errors}
                          options={statesInNigeria}
                          field="state"
                          handleChange={handleChange}
                          value={{ value: values.state, label: values.state }}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          defaultValue={{
                            value: values.state,
                            label: values.state,
                          }}
                        />
                      </Form>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={6}>
                      <Form>
                        <DropDown
                          label="Faculty"
                          touched={touched}
                          errors={errors}
                          options={formatFaculties}
                          field="faculty"
                          setFieldValue={setFieldValue}
                          handleChange={setSelectedFaculty}
                          setFieldTouched={setFieldTouched}
                          value={{
                            value: values.faculty,
                            label: values.faculty,
                          }}
                          defaultValue={{
                            value: values.faculty,
                            label: values.faculty,
                          }}
                        />
                      </Form>
                    </Col>
                    <Col xs={12} md={6}>
                      <DropDown
                        label="L.G.A"
                        name="lga"
                        touched={touched}
                        errors={errors}
                        options={NaijaStates.lgas(
                          values?.state || "Abia"
                        )?.lgas?.map((state: string) => {
                          return { value: state, label: state };
                        })}
                        field="lga"
                        value={{ value: values.lga, label: values.lga }}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        defaultValue={{
                          value: values.lga,
                          label: values.lga,
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form>
                        <DropDown
                          label="Department"
                          touched={touched}
                          errors={errors}
                          options={departmentsOptions}
                          field="department"
                          disabled={!values.faculty}
                          value={{
                            value: values.department,
                            label: values.department,
                          }}
                          defaultValue={{
                            value: values.department,
                            label: values.department,
                          }}
                          setFieldValue={setFieldValue}
                          handleChange={setSelectedDepartment}
                          setFieldTouched={setFieldTouched}
                        />
                      </Form>
                    </Col>
                    <Col xs={12} md={6}>
                      <InputField
                        field="totalScore"
                        label="Score"
                        value={values.totalScore}
                        touched={touched}
                        errors={errors}
                        handleChange={handleChange}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={6}>
                      <Form>
                        <DropDown
                          label="Course"
                          name="programme"
                          touched={touched}
                          errors={errors}
                          disabled={!values.department}
                          options={programmesList}
                          field="programme"
                          handleChange={getMatricNumber}
                          value={{
                            value: values.programme,
                            label: values.programme,
                          }}
                          defaultValue={{
                            value: values.programme,
                            label: values.programme,
                          }}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                        />
                      </Form>
                    </Col>
                    <Col xs={12} md={6}>
                      <InputField
                        field="matricNumberFormation"
                        label="Matric Number Formation"
                        value={
                          matricNumberFormation || values.matricNumberFormation
                        }
                        touched={touched}
                        errors={errors}
                        disabled
                        handleChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form>
                        <DropDown
                          label="Level of Entry"
                          touched={touched}
                          errors={errors}
                          value={{
                            value: values.levelOfEntry,
                            label: values.levelOfEntry,
                          }}
                          defaultValue={{
                            value: values.levelOfEntry,
                            label: values.levelOfEntry,
                          }}
                          options={[
                            { value: "100", label: "100" },
                            { value: "200", label: "200" },
                          ]}
                          field="levelOfEntry"
                          setFieldTouched={setFieldTouched}
                          setFieldValue={setFieldValue}
                        />
                      </Form>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form>
                        <DropDown
                          label="Sex"
                          touched={touched}
                          errors={errors}
                          options={[
                            { value: "M", label: "Male" },
                            { value: "F", label: "Female" },
                          ]}
                          value={{
                            value: values.sex,
                            label:
                              values.sex === "M"
                                ? "MALE"
                                : values.sex === "F"
                                ? "Female"
                                : "",
                          }}
                          defaultValue={{
                            value: values.sex,
                            label: values.sex,
                          }}
                          field="sex"
                          setFieldTouched={setFieldTouched}
                          setFieldValue={setFieldValue}
                        />
                      </Form>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form>
                        <DropDown
                          label="Proceed Status"
                          touched={touched}
                          errors={errors}
                          options={[
                            { value: false, label: "YES" },
                            { value: true, label: "NO" },
                          ]}
                          value={{
                            value: values.isNotApproved,
                            label:
                              values.isNotApproved === ""
                                ? ""
                                : values.isNotApproved
                                ? "NO"
                                : "YES",
                          }}
                          defaultValue={{
                            value: values.isNotApproved,
                            label:
                              values.isNotApproved === ""
                                ? ""
                                : values.isNotApproved
                                ? "NO"
                                : "YES",
                          }}
                          field="isNotApproved"
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                        />
                      </Form>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form>
                        <DropDown
                          label="Session"
                          name="session"
                          touched={touched}
                          errors={errors}
                          options={[{ value: session, label: session }]}
                          field="session"
                          value={{
                            value: values.session,
                            label: values.session,
                          }}
                          defaultValue={{
                            value: values.session,
                            label: values.session,
                          }}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                        />
                      </Form>
                    </Col>
                  </Row>

                  {values?.isNotApproved || values.isNotApproved === "NO" ? (
                    <Row>
                      <Col xs={12} md={6}>
                        <InputField
                          component="textarea"
                          field="notApprovedReason"
                          label="Proceed Status Details"
                          value={values.notApprovedReason}
                          touched={touched}
                          errors={errors}
                          handleChange={handleChange}
                          setFieldTouched={setFieldTouched}
                        />
                      </Col>
                    </Row>
                  ) : null}
                  <div className="modal-footer">
                    <Button
                      className="cancel-btn"
                      onClick={() => {
                        setShow(false);
                        setStudentInfo(initialValues);
                        setMatricNumberFormation("");
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="submit-btn"
                      type="submit"
                      disabled={addNewStudentLoading}
                    >
                      {addNewStudentLoading ? (
                        <DotLoader
                          color="white"
                          loading={addNewStudentLoading}
                          size={30}
                          aria-label="Submitting"
                        />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default StudentModal;
