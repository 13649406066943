import React, { useEffect, useState } from "react";
import "./index.scss";
import { InAppTemplate } from "../../shared/templates/portal";
import Navigation from "../../shared/components/navigation/Navigation";
import Select from "react-select";
import { Button, ButtonGroup, Dropdown, Modal, Table } from "react-bootstrap";
import Filter from "../../assets/filter.png";
import { connect } from "react-redux";
import { adminInAppActions } from "../../redux/action/portal";
import { appConstantsPortal } from "../../redux/action-constants/portal";
import ReactPaginate from "react-paginate";
import { LoadingItem } from "../../shared/components/loading";
import { AlertMsg } from "../../shared/components/alert-msg";

const adminGetNelfundRequest = async ({
  pageProps,
  payload,
  isSearch,
}: any) => {
  await pageProps.adminGetNelfundAction(payload, isSearch);
};

const adminGetNelfundDownloadRequest = async ({ pageProps, payload }: any) => {
  await pageProps.adminDownloadNelfundAction(payload);
};

const ReportTable = ({
  reportData,
  searchQuery,
  setSearchQuery,
  setShowFilter,
}: any) => {
  return (
    <>
      <div className="inputWrap">
        <input
          type="text"
          className="input_wrap search_wrap"
          placeholder="Search"
          value={searchQuery}
          onChange={(e: any) => {
            setSearchQuery(e.target.value);
            setShowFilter(false);
          }}
        />
      </div>
      <Table hover>
        <thead>
          <tr>
            <th>
              <span>Student Name</span>
            </th>
            <th>
              <span>Matric Number</span>
            </th>
            <th>
              <span>Email</span>
            </th>
            <th>
              <span>Gender</span>
            </th>
            <th>Faculty</th>
            <th>Department</th>
            <th>Course of Study</th>
            <th>Level</th>
            <th>State</th>
          </tr>
        </thead>
        <tbody>
          {reportData &&
            reportData.map((eachData: any, index: any) => {
              const { surName, firstName, otherName } = eachData;
              const fullName = `${surName} ${firstName} ${otherName}`;
              return (
                <tr key={index}>
                  <td>{fullName} </td>
                  <td>{eachData?.matricNumber} </td>
                  <td>{eachData?.email} </td>
                  <td>{eachData?.gender} </td>
                  <td>{eachData?.faculty} </td>
                  <td>{eachData?.department} </td>
                  <td>{eachData?.programme} </td>
                  <td>{eachData?.level} </td>
                  <td>{eachData?.state} </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </>
  );
};

const LoadRecordsWrap = ({
  pageSize,
  pageNumber,
  setPageNumber,
  setShouldFetch,
  searchQuery,
  setSearchQuery,
  setShowFilter,
  requestData,
}: any) => {
  const handlePageClick = (event: any) => {
    setPageNumber(event?.selected + 1);
  };

  return (
    <div className="records_wrap">
      {requestData?.items && (
        <>
          {requestData?.totalCount >= 1 && requestData?.items.length >= 1 && (
            <ReportTable
              reportData={requestData?.items}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              setShouldFetch={setShouldFetch}
              setShowFilter={setShowFilter}
            />
          )}
          {(requestData?.totalCount === 0 ||
            requestData?.items.length === 0) && (
            <div className="empty_record"> No Records found</div>
          )}
          {requestData?.totalCount >= 1 && requestData?.items.length >= 1 && (
            <div className="records_count">
              Showing{" "}
              {requestData?.totalCount > requestData?.items.length
                ? `${requestData?.items.length} of ${requestData?.totalCount}`
                : `${requestData?.items.length}`}{" "}
              result{requestData?.items.length > 1 ? "s" : ""}{" "}
            </div>
          )}
          {requestData?.totalCount > pageSize &&
            requestData.items.length >= 1 && (
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                // initialPage={1}
                onPageChange={handlePageClick}
                pageRangeDisplayed={4}
                forcePage={pageNumber - 1}
                // onClick={handleItemsClick}
                className="pagination_items"
                pageClassName="page_num"
                pageLinkClassName="page_link"
                activeClassName="active_page_link"
                previousClassName="previous_page_link"
                nextClassName="next_page_link"
                pageCount={requestData?.totalPages}
                previousLabel="<"
                // @ts-ignore
                renderOnZeroPageCount={null}
              />
            )}
        </>
      )}
    </div>
  );
};

const RecordFiltersWrap = ({
  pageProps,
  endDate,
  isFilterShown,
  setShowFilter,
  setSessionFilter,
  sessionFilter,
  userData,
  setShouldFetch,
  shouldFetch,
  setSearchQuery,
  fileType,
  setFileType,
  handleDownload,
  requestData,
  downloadNelfundRequest,
}: any) => {
  const handleFetch = () => {
    setSearchQuery("");
    setShouldFetch(!shouldFetch);
    setShowFilter(false);
  };

  return (
    <>
      {isFilterShown && (
        <Modal
          contentClassName="common-report-modal"
          className="filter_modal"
          show={isFilterShown}
          onHide={() => {
            setShowFilter(false);
          }}
        >
          <Modal.Body>
            <div className="records_filter_wrap">
              <div className="nelfund_records_filter_wrap">
                <div className="search_course_of_study">
                  <label htmlFor="">Session</label>
                  <Select
                    options={[
                      {
                        label: userData?.currentSession?.session,
                        value: userData?.currentSession?.session,
                      },
                    ]}
                    value={{ label: sessionFilter, value: sessionFilter }}
                    onChange={(option: any) => {
                      setSessionFilter(option?.value);
                    }}
                    placeholder="Select session..."
                    className="select-wrap"
                    classNamePrefix="course-select"
                  />
                </div>
              </div>
            </div>
            <div className="filter_proceed">
              <button className="btn" onClick={handleFetch}>
                Fetch Report
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}

      <div className="fetch_ctas">
        <div className="filter_summary">
          {sessionFilter}
          <span> session </span>
          <span>NelFund report</span>{" "}
        </div>

        <div className="report_filters">
          <div className="ctas">
            <button className="btn filter" onClick={() => setShowFilter(true)}>
              <img src={Filter} alt="" />
              Filter
            </button>

            <Dropdown as={ButtonGroup}>
              <Button
                disabled={
                  downloadNelfundRequest?.is_request_processing ||
                  !requestData?.items?.length
                }
                onClick={handleDownload}
                variant="success"
                className="download_cta"
              >
                {downloadNelfundRequest?.is_request_processing
                  ? `Downloading as ${fileType}...`
                  : ` Download as ${fileType} `}
              </Button>

              <Dropdown.Toggle
                disabled={downloadNelfundRequest?.is_request_processing}
                split
                variant="success"
                id="dropdown-split-basic"
              />

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setFileType("CSV")}>
                  CSV
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setFileType("EXCEL")}>
                  Excel
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setFileType("PDF")}>
                  Pdf
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </>
  );
};

const AllRecordsWrap = ({ pageProps, defaultEndDate, userData }: any) => {
  const [endDate, setEndDate] = useState<any>(defaultEndDate);
  const [isFilterShown, setShowFilter] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [sessionFilter, setSessionFilter] = useState<any>(
    userData?.currentSession?.session || ""
  );
  const [shouldFetch, setShouldFetch] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<any>("");
  const [fileType, setFileType] = useState<any>("CSV");
  const pageSize = 20;
  let fetchReportRequest = pageProps?.adminGetNelfundReducer;
  let requestData =
    pageProps?.adminGetNelfundReducer?.request_data?.response?.data;
  let downloadNelfundRequest = pageProps?.adminDownloadNelfundReducer;
  let payload = `?PageNumber=${pageNumber}&PageSize=${pageSize}&Session=${sessionFilter}`;

  useEffect(() => {
    let searchDebounce: any;
    let requestPayload = payload;
    if (searchQuery?.length) {
      requestPayload = `${payload}&SearchQuery=${searchQuery}`;
    }
    searchDebounce = setTimeout(() => {
      adminGetNelfundDownloadRequest({
        pageProps,
        payload: "CLEAR",
      });

      adminGetNelfundRequest({
        pageProps,
        payload: requestPayload,
        isSearch: searchQuery.length >= 1,
      });
    }, 1000);
    return () => clearTimeout(searchDebounce);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, pageNumber]);

  const handleDownload = () => {
    let fileDownloadPayload = `${payload}&FileType=${fileType}`;
    adminGetNelfundDownloadRequest({
      pageProps,
      payload: fileDownloadPayload,
    });
  };

  return (
    <div className="all_reports_wrap">
      <div className="back-to-report">
        <Navigation to="/app/reports" text="Back to Report Dashboard" />
      </div>

      <>
        {fetchReportRequest?.request_status ===
          appConstantsPortal.GET_NELFUND_REPORTS_PENDING && <LoadingItem />}
        {fetchReportRequest?.request_status ===
          appConstantsPortal.GET_NELFUND_REPORTS_FAILURE && (
          <AlertMsg
            type="error"
            message={fetchReportRequest?.request_data.error}
          />
        )}

        {fetchReportRequest?.request_status ===
          appConstantsPortal.GET_NELFUND_REPORTS_SUCCESS && (
          <>
            <RecordFiltersWrap
              setEndDate={setEndDate}
              endDate={endDate}
              setSessionFilter={setSessionFilter}
              sessionFilter={sessionFilter}
              isFilterShown={isFilterShown}
              setShowFilter={setShowFilter}
              userData={userData}
              setShouldFetch={setShouldFetch}
              shouldFetch={shouldFetch}
              setSearchQuery={setSearchQuery}
              fileType={fileType}
              setFileType={setFileType}
              requestData={requestData}
              handleDownload={handleDownload}
              downloadNelfundRequest={downloadNelfundRequest}
            />

            {downloadNelfundRequest?.request_status ===
              appConstantsPortal.GET_NELFUND_DOWNLOAD_REPORTS_FAILURE && (
              <AlertMsg
                type="error"
                message={downloadNelfundRequest?.request_data.error}
              />
            )}

            {downloadNelfundRequest?.request_status ===
              appConstantsPortal.GET_NELFUND_DOWNLOAD_REPORTS_SUCCESS && (
              <div className="alert-msg success">
                <div className="alert-txt">
                  You can now{" "}
                  <a
                    className="dl_link"
                    target="_blank"
                    rel="noreferrer"
                    href={downloadNelfundRequest?.request_data?.response?.data}
                  >
                    download the report
                  </a>{" "}
                </div>
              </div>
            )}

            <LoadRecordsWrap
              pageProps={pageProps}
              pageSize={pageSize}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              setShouldFetch={setShouldFetch}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              setShowFilter={setShowFilter}
              shouldFetch={shouldFetch}
              requestData={requestData}
            />
          </>
        )}
      </>
    </div>
  );
};

const NelfundReportWrap = (pageProps: any) => {
  let loggedAccountInfo: any = localStorage.getItem("adXAuth") || "{}";
  loggedAccountInfo = JSON.parse(loggedAccountInfo);
  let defaultStartDate = new Date(new Date().setDate(new Date().getDate() - 7));
  let defaultEndDate = new Date();
  return (
    <div>
      <InAppTemplate
        pageTitle="Nelfund Report"
        childComponent={
          <AllRecordsWrap
            pageProps={pageProps}
            userData={loggedAccountInfo}
            defaultStartDate={defaultStartDate}
            defaultEndDate={defaultEndDate}
          />
        }
      />
    </div>
  );
};

const mapDispatchToProps = {
  adminGetNelfundAction: adminInAppActions.AdminGetNelFund,
  adminDownloadNelfundAction: adminInAppActions.AdminDownloadNelfundReport,
};

const mapStateToProps = (state: any) => ({
  adminGetNelfundReducer: state.allUGradPortalReducers.adminGetNelFundReducer,
  adminDownloadNelfundReducer:
    state.allUGradPortalReducers.adminDownloadNelFundReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(NelfundReportWrap);
