import { routes } from "../../../services/paths";
import { ApiService } from "../../../services/apiService";
import { handleRequestErrors } from "../../../shared/utils";

export const getAllRooms = async (pageNumber: number) => {
  return await ApiService.request({
    url: `${routes.HOSTEL_ROOMS}?pageNumber=${pageNumber}&pageSize=20`,
    http_method: "GET",
    headers: { "X-Signature": routes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};

export const getStudentsInRoom = async (roomId: string, pageNumber: number) => {
  return await ApiService.request({
    url: `${routes.STUDENTS_IN_ROOM}?roomUniqueId=${roomId}&pageNumber=${pageNumber}&pageSize=20`,
    http_method: "GET",
    headers: { "X-Signature": routes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};

export const addOrUpdateRoom = async (payload: any) => {
  return await ApiService.request({
    url: `${routes.ADD_OR_UPDATE_ROOM}`,
    http_method: "POST",
    data: payload,
    headers: { "X-Signature": routes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};

export const getPaginatedHostelSiteBlock = async (pageNumber: number) => {
  return await ApiService.request({
    url: `${routes.PAGINATED_HOSTEL_SITE_BLOCKS}?pageNumber=${pageNumber}&pageSize=20`,
    http_method: "GET",
    headers: { "X-Signature": routes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};

export const getHostelSiteBlock = async () => {
  return await ApiService.request({
    url: `${routes.HOSTEL_SITE_BLOCKS}`,
    http_method: "GET",
    headers: { "X-Signature": routes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};

export const deleteStudentFromRoom = async (payload: any) => {
  const { studentId, bedSpaceUniqueId, roomUniqueId } = payload;
  return await ApiService.request({
    url: `${routes.DELETE_STUDENT_FROM_ROOM}?studentId=${studentId}&bedSpaceUniqueId=${bedSpaceUniqueId}&roomUniqueId=${roomUniqueId}`,
    http_method: "DELETE",
    data: payload,
    headers: { "X-Signature": routes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};

export const addHostelToRoom = async (payload: any) => {
  return await ApiService.request({
    url: `${routes.ADD_STUDENT_TO_ROOM}`,
    http_method: "POST",
    data: payload,
    headers: { "X-Signature": routes.apiKey },
  });
};

export const addOrEditRoom = async (payload: any) => {
  return await ApiService.request({
    url: `${routes.ADD_OR_EDIT_ROOM}`,
    http_method: "POST",
    data: payload,
    headers: { "X-Signature": routes.apiKey },
  })
    .then(async (response: any) => {
      return response;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
    });
};
export const addOrUpdatePercentage = async (payload: any) => {
  return await ApiService.request({
    url: `${routes.ADD_OR_UPDATE_BEDSPACE_PERCENTAGE}`,
    http_method: "POST",
    data: payload,
    headers: { "X-Signature": routes.apiKey },
  })
    .then(async (response: any) => {
      return response;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};

export const getHostelDashboardStat = async () => {
  return await ApiService.request({
    url: `${routes.HOSTEL_ACCOMODATION_DASHBOARD_STATISTICS}`,
    http_method: "GET",
    headers: { "X-Signature": routes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};

export const getHostelPercentage = async () => {
  return await ApiService.request({
    url: `${routes.GET_BEDSPACE_PERCENTAGE}`,
    http_method: "GET",
    headers: { "X-Signature": routes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};
