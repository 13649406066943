import React, { useEffect, useMemo, useState } from "react";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import Button from "react-bootstrap/Button";
import { DotLoader } from "react-spinners";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import "./index.scss";
import InputField from "../../shared/components/InputField";
import DropDown from "../../shared/components/dropdown";
import { Col, Row } from "react-bootstrap";
import { addOrUpdateRoom } from "../../redux/action/hostels";
import { toast } from "react-toastify";

type Payload = {
  blockUniqueId: string;
  roomUniqueId: string;
  hostelSite: string;
  blockName: string;
  rooomName: string;
  gender: string;
  totalBedSpace: number;
  isReserved: boolean;
  allocatedBedSpace: number;
  amount: number;
  session: string;

};

const RoomModal = ({
  show,
  setShow,
  roomInfo,
  setRoomInfo,
  blocks,
  setRefetch,
  refetch,
  initialValues,
}: any) => {
  const [selectedSite, setSelectedSite] = useState("");
  const [selectedBlock, setSelectedBlock] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSelectedSite(roomInfo?.hostelSite);
    setSelectedBlock(roomInfo?.blockUniqueId);
  }, [roomInfo]);

  let loggedAccountInfo: any = localStorage.getItem("adXAuth") || "{}";
  loggedAccountInfo = JSON.parse(loggedAccountInfo);

  let checkValidationSchema = Yup.object().shape({
    hostelSite: Yup.string().required("Required"),
    blockName: Yup.string().required("Required"),
    roomName: Yup.string().required("Required"),
    totalBedSpace: Yup.string().required("Required"),
    isReserved: Yup.boolean().required("Required"),
  
  });

  const siteList = blocks
    .map((block: any) => ({
      label: block?.siteName,
      value: block?.siteName,
    }))
    .filter(
      (el: any, index: number, arr: any) =>
        index === arr.findIndex((x: any) => x.value === el.value)
    );

  const selectedBlockDetails = useMemo(
    () =>
      blocks.find(
        (el: any) => el.hostelBlocks?.blockUniqueId === selectedBlock
      ),
    [blocks, selectedBlock]
  );
  const hostelBlocksList = useMemo(
    () =>
      blocks
        .filter((el: any) => el.siteName === selectedSite)
        .map((block: any) => ({
          label: block?.hostelBlocks?.blockName,
          value: block?.hostelBlocks?.blockUniqueId,
        })),
    [blocks, selectedSite]
  );

  const handleCloseModal = () => {
    setShow(false);
    setRoomInfo(initialValues);
    setSelectedBlock("");
    setSelectedSite("");
    setLoading(false);
  };
  return (
    <Modal
      show={show}
      onHide={handleCloseModal}
      className="course_subject_wrap"
      size="lg"
      contentClassName="course-subject-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">ADD ROOM</Modal.Title>
      </Modal.Header>
      <Modal.Body className="student-modal-form">
        <Container>
          <Formik
            initialValues={roomInfo}
            validationSchema={checkValidationSchema}
            onSubmit={async (values) => {
              setLoading(true);
              const {
                hostelSite,
                roomName,
                totalBedSpace,
                allocatedBedSpace,
                isReserved,
              } = values;

              let payload: Payload = {
                blockUniqueId: selectedBlock,
                roomUniqueId: values.roomUniqueId,
                hostelSite,
                blockName: selectedBlockDetails?.hostelBlocks?.blockName,
                rooomName: roomName,
                gender: selectedBlockDetails?.hostelBlocks?.gender,
                totalBedSpace: Number(totalBedSpace),
                isReserved,
                allocatedBedSpace: Number(allocatedBedSpace),
                amount: Number(selectedBlockDetails?.hostelBlocks?.amount),
                session: loggedAccountInfo?.currentSession.session,
              };

              const res = await addOrUpdateRoom(payload);
              setLoading(false);
              if (res?.status === 200) {
                setShow(false);
                setRefetch(refetch === null ? true : !refetch);
                setRoomInfo(initialValues);
                setSelectedBlock("");
                setSelectedSite("");
                toast.success(
                  values?.roomUniqueId
                    ? "Room updated successfully"
                    : "Room created successfully"
                );
              } else {
                toast.error("An error occured");
              }
            }}
          >
            {(props: FormikProps<any>) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                setFieldValue,
                setFieldTouched,
              } = props;

              return (
                <Form>
                  <Row>
                    <Col xs={12} md={6}>
                      <DropDown
                        field="hostelSite"
                        label="Site Name"
                        touched={touched}
                        errors={errors}
                        options={siteList}
                        value={{
                          value: values?.hostelSite,
                          label: values?.hostelSite,
                        }}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        handleChange={setSelectedSite}
                        width={"97%"}
                      />
                    </Col>
                    <Col>
                      {/* hostelBlocksList */}
                      <DropDown
                        field="blockName"
                        label="Block Name"
                        touched={touched}
                        errors={errors}
                        options={hostelBlocksList}
                        value={{
                          value: selectedBlockDetails?.hostelBlocks?.blockName,
                          label: selectedBlockDetails?.hostelBlocks?.blockName,
                        }}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        handleChange={setSelectedBlock}
                        width={"97%"}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <InputField
                        field="roomName"
                        label="Room Name"
                        value={values.roomName}
                        touched={touched}
                        errors={errors}
                        handleChange={handleChange}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <Form>
                        <DropDown
                          label="Gender"
                          touched={touched}
                          errors={errors}
                          options={[
                            { label: "Male", value: "M" },
                            { label: "Female", value: "F" },
                          ]}
                          value={{
                            value:
                              selectedBlockDetails?.hostelBlocks?.gender === "M"
                                ? "Male"
                                : selectedBlockDetails?.hostelBlocks?.gender ===
                                  "F"
                                ? "Female"
                                : "",
                            label:
                              selectedBlockDetails?.hostelBlocks?.gender === "M"
                                ? "Male"
                                : selectedBlockDetails?.hostelBlocks?.gender ===
                                  "F"
                                ? "Female"
                                : "",
                          }}
                          field="gender"
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          width={"97%"}
                          disabled
                        />
                      </Form>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <InputField
                        field="totalBedSpace"
                        label="Bed Space"
                        value={values.totalBedSpace}
                        touched={touched}
                        errors={errors}
                        handleChange={handleChange}
                      />
                    </Col>
                    <Col>
                      <DropDown
                        label="Reserved?"
                        touched={touched}
                        errors={errors}
                        options={[
                          { label: "YES", value: true },
                          { label: "NO", value: false },
                        ]}
                        field="isReserved"
                        value={{
                          value: values.isReserved,
                          label:
                            values.isReserved === true
                              ? "YES"
                              : values.isReserved === false
                              ? "NO"
                              : "",
                        }}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        width={"97%"}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <InputField
                        field="allocatedBedSpace"
                        label="Allocated Space"
                        value={values.allocatedBedSpace}
                        touched={touched}
                        errors={errors}
                        handleChange={handleChange}
                        disabled
                        mandatory={false}
                      />
                    </Col>
                    <Col>
                      <InputField
                        field="amount"
                        label="Amount"
                        value={selectedBlockDetails?.hostelBlocks?.amount}
                        touched={touched}
                        errors={errors}
                        handleChange={handleChange}
                        disabled
                      />
                    </Col>
                  </Row>

                  <div className="modal-footer">
                    <Button
                      className="cancel-btn"
                      onClick={handleCloseModal}
                      disabled={loading}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="submit-btn"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? (
                        <DotLoader
                          color="white"
                          loading={loading}
                          size={30}
                          aria-label="Submitting"
                        />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default RoomModal;
