import "./index.scss";
import Card from "../postutme/Card";
import { HOSTEL_ACCOMMODATION_LIST } from "../../shared/_helpers/constants";
import HostelDashboard from "./hostel-dashboard";


const HostelMenu = () => {

  return (
    <>
        <div className="menu-container">
    {HOSTEL_ACCOMMODATION_LIST.map((item: any, index: number) => (
        <Card
          key={index}
          title={item.title}
          buttonText={item.buttonText}
          icon={item.icon}
          backgroundColor={item.backgroundColor}
          routeTo={item.routeTo}

        />
      ))}
      </div>

      <div>
        <HostelDashboard />
      </div>
      </>

  );
};

export default HostelMenu;