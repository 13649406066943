import Dash1 from "../../../assets/dash-1.png";
import HostelCard from "./HostelCard";

const HostelDashboard = () => {

  let loggedAccountInfo: any = localStorage.getItem("adXAuth") || "{}";
  loggedAccountInfo = JSON.parse(loggedAccountInfo);
  const session = loggedAccountInfo?.currentSession.session;

  return (
    <div>
      <div className="dashboard_stats">
        <div className="tab1_container">
          <div className="top_container">
            <div className="top_stat" style={{ backgroundColor: "green" }}>
              <div className="top_icon">
                <img src={Dash1} alt="" />
              </div>
              <div className="top_values">
                <div className="stat">CURRENT HOSTEL SESSION</div>
                <div className="vaalue">
                  {" "}
                  <h2>{session}</h2>{" "}
                </div>
              </div>
            </div>
          </div>

           <div className="tab-wrapper">
                <HostelCard />
          </div> 
        </div>
      </div>
    </div>
  );
};

export default HostelDashboard;
