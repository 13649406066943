import { ApiService } from "../../../services/apiService";
import { routes } from "../../../services/paths";
import { Dispatch } from "redux";

import { appConstantsPortal } from "../../action-constants/portal";

import { handleRequestErrors } from "../../../shared/utils";

const AdminLoadDashboard = (actionPayload: any) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let apiConsume = ApiService.request({
        url: `${routes.LOAD_DASHBOARD}`,
        http_method: "GET",
        data: null,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return { type: appConstantsPortal.LOAD_DASHBOARD_PENDING, params };
  }
  function success(response: any) {
    return { type: appConstantsPortal.LOAD_DASHBOARD_SUCCESS, response };
  }
  function failure(error: any) {
    return { type: appConstantsPortal.LOAD_DASHBOARD_FAILURE, error };
  }
  function clear() {
    return { type: appConstantsPortal.LOAD_DASHBOARD_RESET, clear_data: "" };
  }
};

const AdminLoadCourseRegMeta = (
  actionPayload: any,
  isProgramAlone?: boolean,
  shouldFetchOldSessions?: boolean
) => {
  if (actionPayload !== "CLEAR") {
    if (!isProgramAlone) {
      return (dispatch: Dispatch) => {
        let courseregMeta: any;
        let apiConsume = ApiService.request({
          url: `${routes.GET_ALL_COURSE_SUBJECTS}`,
          http_method: "GET",
          data: null,
          headers: { "X-Signature": routes.apiKey },
        });
        dispatch(request(apiConsume));
        return apiConsume
          .then((response: any) => {
            if (response?.data && response?.data.length >= 1) {
              let apiConsume2 = ApiService.request({
                url: `${routes.GET_ALL_FACULTY_AND_DEPT}`,
                http_method: "GET",
                data: null,
                headers: { "X-Signature": routes.apiKey },
              });
              // dispatch(request(apiConsume2));
              return apiConsume2
                .then((response2: any) => {
                  if (response2?.data && response2?.data.length >= 1) {
                    let apiConsume3 = ApiService.request({
                      url: `${routes.GET_ALL_PROGRAMMES}`,
                      http_method: "GET",
                      data: null,
                      headers: { "X-Signature": routes.apiKey },
                    });
                    // dispatch(request(apiConsume3));
                    return apiConsume3
                      .then((response3: any) => {
                        if (response3?.data && response3?.data.length >= 1) {
                          let apiConsume4 = ApiService.request({
                            url: `${routes.GET_CURRENT_SESSION}`,
                            http_method: "GET",
                            data: null,
                            headers: { "X-Signature": routes.apiKey },
                          });
                          // dispatch(request(apiConsume4));
                          return apiConsume4
                            .then((response4: any) => {
                              if (response4?.data) {
                                courseregMeta = {
                                  allSubjects: response?.data,
                                  allDeptAndFaculty: response2?.data,
                                  allProgrammes: response3?.data,
                                  currentSession: response4?.data,
                                  sessions:{}
                                };
                                dispatch(success(courseregMeta));
                              } else {
                                dispatch(
                                  failure(
                                    "Current Session information not loaded"
                                  )
                                );
                              }
                            })
                            .catch((error: any) => {
                              dispatch(failure(handleRequestErrors(error)));
                            });
                        } else {
                          dispatch(failure("No Course of Study loaded"));
                        }
                      })
                      .catch((error: any) => {
                        dispatch(failure(handleRequestErrors(error)));
                      });
                  } else {
                    dispatch(failure("No Departments loaded"));
                  }
                })
                .catch((error: any) => {
                  dispatch(failure(handleRequestErrors(error)));
                });
            } else {
              dispatch(failure("No Courses loaded"));
            }
          })
          .catch((error: any) => {
            dispatch(failure(handleRequestErrors(error)));
          });
      };
    }
    if (isProgramAlone) {
      return (dispatch: Dispatch) => {
        let apiConsume3 = ApiService.request({
          url: `${routes.GET_ALL_PROGRAMMES}`,
          http_method: "GET",
          data: null,
          headers: { "X-Signature": routes.apiKey },
        });
        // dispatch(request(apiConsume3));
        return apiConsume3
          .then((response3: any) => {
            if (response3?.data && response3?.data.length >= 1) {
              let mainData:any = {
                allProgrammes: response3?.data,
                sessions:{}
              };
              if(shouldFetchOldSessions){
                let apiConsume4 = ApiService.request({
                  url: `${routes.GET_OLD_SESSIONS}`,
                  http_method: "GET",
                  data: null,
                  headers: { "X-Signature": routes.apiKey },
                });
                return apiConsume4
                  .then((response4:any)=>{
                    if(response4?.data && Object.keys(response4?.data).length >= 1){

                      mainData.sessions = response4.data;
                      dispatch(success(mainData));
                    }else {
                      dispatch(failure("No previous sessions loaded"));
                    }
                  })
                  .catch()
              }else{
              dispatch(success(mainData));
            }
            } else {
              dispatch(failure("No Course of Study loaded"));
            }
          })
          .catch((error: any) => {
            dispatch(failure(handleRequestErrors(error)));
          });
      };
    }
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return { type: appConstantsPortal.FETCH_COURSE_REG_META_PENDING, params };
  }
  function success(response: any) {
    return { type: appConstantsPortal.FETCH_COURSE_REG_META_SUCCESS, response };
  }
  function failure(error: any) {
    return { type: appConstantsPortal.FETCH_COURSE_REG_META_FAILURE, error };
  }
  function clear() {
    return {
      type: appConstantsPortal.FETCH_COURSE_REG_META_RESET,
      clear_data: "",
    };
  }
};

const AdminLoadPresetCourseReg = (actionPayload: any) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let apiConsume = ApiService.request({
        url: `${routes.GET_PRESET_COURSES}`,
        http_method: "POST",
        data: actionPayload,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return { type: appConstantsPortal.LOAD_PRESET_COURSE_REG_PENDING, params };
  }
  function success(response: any) {
    return {
      type: appConstantsPortal.LOAD_PRESET_COURSE_REG_SUCCESS,
      response,
    };
  }
  function failure(error: any) {
    return { type: appConstantsPortal.LOAD_PRESET_COURSE_REG_FAILURE, error };
  }
  function clear() {
    return {
      type: appConstantsPortal.LOAD_PRESET_COURSE_REG_RESET,
      clear_data: "",
    };
  }
};

const AdminSavePresetCourseReg = (actionPayload: any, isUpdate: boolean) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url = !isUpdate
        ? routes.SAVE_PRESET_COURSES
        : routes.UPDATE_PRESET_COURSES;
      let apiConsume = ApiService.request({
        url,
        http_method: "POST",
        data: actionPayload,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return { type: appConstantsPortal.SAVE_PRESET_COURSE_REG_PENDING, params };
  }
  function success(response: any) {
    return {
      type: appConstantsPortal.SAVE_PRESET_COURSE_REG_SUCCESS,
      response,
    };
  }
  function failure(error: any) {
    return { type: appConstantsPortal.SAVE_PRESET_COURSE_REG_FAILURE, error };
  }
  function clear() {
    return {
      type: appConstantsPortal.SAVE_PRESET_COURSE_REG_RESET,
      clear_data: "",
    };
  }
};

const AdminDeletePresetCourseReg = (actionPayload: any, isUpdate: boolean) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let apiConsume = ApiService.request({
        url: `${routes.DELETE_PRESET_COURSES}${actionPayload}`,
        http_method: "DELETE",
        data: null,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return {
      type: appConstantsPortal.DELETE_PRESET_COURSE_REG_PENDING,
      params,
    };
  }
  function success(response: any) {
    return {
      type: appConstantsPortal.DELETE_PRESET_COURSE_REG_SUCCESS,
      response,
    };
  }
  function failure(error: any) {
    return { type: appConstantsPortal.DELETE_PRESET_COURSE_REG_FAILURE, error };
  }
  function clear() {
    return {
      type: appConstantsPortal.DELETE_PRESET_COURSE_REG_RESET,
      clear_data: "",
    };
  }
};

const AdminGetStudentsList = (actionPayload: any) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let apiConsume = ApiService.request({
        url: `${routes.GET_ALL_STUDENTS}${actionPayload}`,
        http_method: "GET",
        data: null,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return { type: appConstantsPortal.GET_STUDENTS_LIST_PENDING, params };
  }
  function success(response: any) {
    return { type: appConstantsPortal.GET_STUDENTS_LIST_SUCCESS, response };
  }
  function failure(error: any) {
    return { type: appConstantsPortal.GET_STUDENTS_LIST_FAILURE, error };
  }
  function clear() {
    return { type: appConstantsPortal.GET_STUDENTS_LIST_RESET, clear_data: "" };
  }
};

const adminSearchStudents = (actionPayload: any) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let apiConsume = ApiService.request({
        url: `${routes.SEARCH_STUDENT}${actionPayload}`,
        http_method: "GET",
        data: null,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return { type: appConstantsPortal.GET_STUDENTS_LIST_PENDING, params };
  }
  function success(response: any) {
    return { type: appConstantsPortal.GET_STUDENTS_LIST_SUCCESS, response };
  }
  function failure(error: any) {
    return { type: appConstantsPortal.GET_STUDENTS_LIST_FAILURE, error };
  }
  function clear() {
    return { type: appConstantsPortal.GET_STUDENTS_LIST_RESET, clear_data: "" };
  }
};

const AdminGetAStudent = (actionPayload: any, isForChangeOfCourse: boolean) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let apiConsume = ApiService.request({
        url: `${routes.GET_A_STUDENT}`,
        http_method: "POST",
        data: actionPayload,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          if (!isForChangeOfCourse) {
            dispatch(success(response));
          } else {
            let apiConsume2 = ApiService.request({
              url: `${routes.GET_ALL_FACULTY_AND_DEPT}`,
              http_method: "GET",
              data: null,
              headers: { "X-Signature": routes.apiKey },
            });
            // dispatch(request(apiConsume2));
            return apiConsume2
              .then((response2: any) => {
                if (response2?.data && response2?.data.length >= 1) {
                  let apiConsume3 = ApiService.request({
                    url: `${routes.GET_ALL_PROGRAMMES}`,
                    http_method: "GET",
                    data: null,
                    headers: { "X-Signature": routes.apiKey },
                  });
                  // dispatch(request(apiConsume3));
                  return apiConsume3
                    .then((response3: any) => {
                      if (response3?.data && response3?.data.length >= 1) {
                        let courseChangeMeta = {
                          data: response?.data,
                          allDeptAndFaculty: response2?.data,
                          allProgrammes: response3?.data,
                        };
                        dispatch(success(courseChangeMeta));
                      } else {
                        dispatch(failure("No Programmes loaded"));
                      }
                    })
                    .catch((error: any) => {
                      dispatch(failure(handleRequestErrors(error)));
                    });
                } else {
                  dispatch(failure("No Departments loaded"));
                }
              })
              .catch((error: any) => {
                dispatch(failure(handleRequestErrors(error)));
              });
          }
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return { type: appConstantsPortal.GET_A_STUDENT_PENDING, params };
  }
  function success(response: any) {
    return { type: appConstantsPortal.GET_A_STUDENT_SUCCESS, response };
  }
  function failure(error: any) {
    return { type: appConstantsPortal.GET_A_STUDENT_FAILURE, error };
  }
  function clear() {
    return { type: appConstantsPortal.GET_A_STUDENT_RESET, clear_data: "" };
  }
};

const AdminGetAStudentInvoice = (actionPayload: any, invoiceType: string) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url: any;

      if (invoiceType === "paystack") {
        url = `${routes.GET_STUDENT_PAYSTACK_INVOICE}${actionPayload}`;
      }
      if (invoiceType === "cbs") {
        url = `${routes.GET_STUDENT_CBS_INVOICE}${actionPayload}`;
      }

      let apiConsume = ApiService.request({
        url,
        http_method: "GET",
        data: actionPayload,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return { type: appConstantsPortal.GET_STUDENT_INVOICE_PENDING, params };
  }
  function success(response: any) {
    return { type: appConstantsPortal.GET_STUDENT_INVOICE_SUCCESS, response };
  }
  function failure(error: any) {
    return { type: appConstantsPortal.GET_STUDENT_INVOICE_FAILURE, error };
  }
  function clear() {
    return {
      type: appConstantsPortal.GET_STUDENT_INVOICE_RESET,
      clear_data: "",
    };
  }
};

const AdminUpdateAStudentInvoice = (
  actionPayload: any,
  invoiceType: string,
  actionType: string
) => {
  if (actionPayload !== "CLEAR") {
    // return false;

    return (dispatch: Dispatch) => {
      let url: any = "",
        http_method = "POST",
        payload;

      if (invoiceType === "paystack") {
        if (actionType.toLowerCase() === "delete") {
          http_method = "DELETE";
          url = `${routes.DELETE_STUDENT_PAYSTACK_INVOICE}${actionPayload}`;
          payload = null;
        }

        if (actionType.toLowerCase() === "update") {
          http_method = "PUT";
          url = `${routes.UPDATE_STUDENT_PAYSTACK_INVOICE}`;
          payload = actionPayload;
        }
      }
      if (invoiceType === "cbs") {
        if (actionType.toLowerCase() === "delete") {
          http_method = "DELETE";
          url = `${routes.DELETE_STUDENT_CBS_INVOICE}${actionPayload}`;
          payload = null;
        }
        if (actionType.toLowerCase() === "update") {
          http_method = "PUT";
          url = `${routes.UPDATE_STUDENT_CBS_INVOICE}`;
          payload = actionPayload;
        }
      }

      let apiConsume = ApiService.request({
        url,
        http_method,
        data: payload,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return { type: appConstantsPortal.UPDATE_STUDENT_INVOICE_PENDING, params };
  }
  function success(response: any) {
    return {
      type: appConstantsPortal.UPDATE_STUDENT_INVOICE_SUCCESS,
      response,
    };
  }
  function failure(error: any) {
    return { type: appConstantsPortal.UPDATE_STUDENT_INVOICE_FAILURE, error };
  }
  function clear() {
    return {
      type: appConstantsPortal.UPDATE_STUDENT_INVOICE_RESET,
      clear_data: "",
    };
  }
};

const AdminUpdateAStudent = (actionPayload: any, passport: any) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      if (passport) {
        let apiConsume = ApiService.request({
          url: `${routes.UPLOAD_STUDENT_PASSPORT}`,
          http_method: "POST",
          data: passport,
          headers: { "X-Signature": routes.apiKey },
        });
        dispatch(request(apiConsume));
        return apiConsume
          .then((response: any) => {
            if (response?.data) {
              let responseData = response?.data;

              let photoId = responseData.substring(
                responseData.lastIndexOf("/") + 1
              );
              // let bioData = {
              //     ...actionPayload,
              //     photoId
              // }

              let bioData = actionPayload;
              bioData.photoId = photoId;

              let apiConsume2 = ApiService.request({
                url: `${routes.UPDATE_A_STUDENT}`,
                http_method: "POST",
                data: bioData,
                headers: { "X-Signature": routes.apiKey },
              });
              dispatch(request(apiConsume2));
              return apiConsume2
                .then((response2: any) => {
                  dispatch(success(response2.data));
                })
                .catch((error: any) => {
                  dispatch(failure(handleRequestErrors(error)));
                });
            }
          })
          .catch((error: any) => {
            dispatch(failure(handleRequestErrors(error)));
          });
      } else {
        let apiConsume = ApiService.request({
          url: `${routes.UPDATE_A_STUDENT}`,
          http_method: "POST",
          data: actionPayload,
          headers: { "X-Signature": routes.apiKey },
        });
        dispatch(request(apiConsume));
        return apiConsume
          .then((response: any) => {
            dispatch(success(response));
          })
          .catch((error: any) => {
            dispatch(failure(handleRequestErrors(error)));
          });
      }
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return { type: appConstantsPortal.UPDATE_A_STUDENT_PENDING, params };
  }
  function success(response: any) {
    return { type: appConstantsPortal.UPDATE_A_STUDENT_SUCCESS, response };
  }
  function failure(error: any) {
    return { type: appConstantsPortal.UPDATE_A_STUDENT_FAILURE, error };
  }
  function clear() {
    return { type: appConstantsPortal.UPDATE_A_STUDENT_RESET, clear_data: "" };
  }
};

const AdminLoadBiodataMeta = (actionPayload: any) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let courseregMeta: any;
      let apiConsume = ApiService.request({
        url: `${routes.GET_AWARDS_IN_VIEW}${actionPayload}`,
        http_method: "GET",
        data: null,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          if (response?.data?.items && response?.data?.items.length >= 1) {
            let apiConsume2 = ApiService.request({
              url: `${routes.GET_ALL_FACULTY_AND_DEPT}`,
              http_method: "GET",
              data: null,
              headers: { "X-Signature": routes.apiKey },
            });
            // dispatch(request(apiConsume2));
            return apiConsume2
              .then((response2: any) => {
                if (response2?.data && response2?.data.length >= 1) {
                  let apiConsume3 = ApiService.request({
                    url: `${routes.GET_ALL_PROGRAMMES}`,
                    http_method: "GET",
                    data: null,
                    headers: { "X-Signature": routes.apiKey },
                  });
                  // dispatch(request(apiConsume3));
                  return apiConsume3
                    .then((response3: any) => {
                      if (response3?.data && response3?.data.length >= 1) {
                        let apiConsume4 = ApiService.request({
                          url: `${routes.GET_ALL_COUNTRIES}${actionPayload}`,
                          http_method: "GET",
                          data: null,
                          headers: { "X-Signature": routes.apiKey },
                        });
                        // dispatch(request(apiConsume4));
                        return apiConsume4
                          .then((response4: any) => {
                            if (
                              response4?.data?.items &&
                              response4?.data?.items.length >= 1
                            ) {
                              courseregMeta = {
                                allAwards: response?.data?.items,
                                allDeptAndFaculty: response2?.data,
                                allProgrammes: response3?.data,
                                countriesList: response4?.data?.items,
                              };
                              dispatch(success(courseregMeta));
                            } else {
                              dispatch(failure("No Countries loaded"));
                            }
                          })
                          .catch((error: any) => {
                            dispatch(failure(handleRequestErrors(error)));
                          });
                      } else {
                        dispatch(failure("No Programmes loaded"));
                      }
                    })
                    .catch((error: any) => {
                      dispatch(failure(handleRequestErrors(error)));
                    });
                } else {
                  dispatch(failure("No Departments loaded"));
                }
              })
              .catch((error: any) => {
                dispatch(failure(handleRequestErrors(error)));
              });
          } else {
            dispatch(failure("No Awards-In-View Fetched"));
          }
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return { type: appConstantsPortal.FETCH_BIODATA_META_PENDING, params };
  }
  function success(response: any) {
    return { type: appConstantsPortal.FETCH_BIODATA_META_SUCCESS, response };
  }
  function failure(error: any) {
    return { type: appConstantsPortal.FETCH_BIODATA_META_FAILURE, error };
  }
  function clear() {
    return {
      type: appConstantsPortal.FETCH_BIODATA_META_RESET,
      clear_data: "",
    };
  }
};

const AdminGetAwardsInView = (actionPayload: any) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let apiConsume = ApiService.request({
        url: `${routes.GET_AWARDS_IN_VIEW}`,
        http_method: "GET",
        data: null,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return { type: appConstantsPortal.GET_AWARDS_IN_VIEW_PENDING, params };
  }
  function success(response: any) {
    return { type: appConstantsPortal.GET_AWARDS_IN_VIEW_SUCCESS, response };
  }
  function failure(error: any) {
    return { type: appConstantsPortal.GET_AWARDS_IN_VIEW_FAILURE, error };
  }
  function clear() {
    return {
      type: appConstantsPortal.GET_AWARDS_IN_VIEW_RESET,
      clear_data: "",
    };
  }
};

const AdminAddDeleteAwardInView = (actionPayload: any, isAdd: boolean) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url = !isAdd
        ? `${routes.DELETE_AWARD_IN_VIEW}${actionPayload}`
        : routes.ADD_AWARD_IN_VIEW;
      let http_method = !isAdd ? "DELETE" : "POST";
      let apiConsume = ApiService.request({
        url,
        http_method,
        data: !isAdd ? null : actionPayload,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return {
      type: appConstantsPortal.ADD_DELETE_AWARDS_IN_VIEW_PENDING,
      params,
    };
  }
  function success(response: any) {
    return {
      type: appConstantsPortal.ADD_DELETE_AWARDS_IN_VIEW_SUCCESS,
      response,
    };
  }
  function failure(error: any) {
    return {
      type: appConstantsPortal.ADD_DELETE_AWARDS_IN_VIEW_FAILURE,
      error,
    };
  }
  function clear() {
    return {
      type: appConstantsPortal.ADD_DELETE_AWARDS_IN_VIEW_RESET,
      clear_data: "",
    };
  }
};

const AdminGetCountries = (actionPayload: any) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let apiConsume = ApiService.request({
        url: `${routes.GET_ALL_COUNTRIES}`,
        http_method: "GET",
        data: null,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return { type: appConstantsPortal.GET_COUNTRIES_PENDING, params };
  }
  function success(response: any) {
    return { type: appConstantsPortal.GET_COUNTRIES_SUCCESS, response };
  }
  function failure(error: any) {
    return { type: appConstantsPortal.GET_COUNTRIES_FAILURE, error };
  }
  function clear() {
    return { type: appConstantsPortal.GET_COUNTRIES_RESET, clear_data: "" };
  }
};

const AdminAddDeleteCountry = (actionPayload: any, isAdd: boolean) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url = !isAdd
        ? `${routes.DELETE_COUNTRY}${actionPayload}`
        : routes.ADD_COUNTRY;
      let http_method = !isAdd ? "DELETE" : "POST";
      let apiConsume = ApiService.request({
        url,
        http_method,
        data: !isAdd ? null : actionPayload,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return { type: appConstantsPortal.ADD_DELETE_COUNTRIES_PENDING, params };
  }
  function success(response: any) {
    return { type: appConstantsPortal.ADD_DELETE_COUNTRIES_SUCCESS, response };
  }
  function failure(error: any) {
    return { type: appConstantsPortal.ADD_DELETE_COUNTRIES_FAILURE, error };
  }
  function clear() {
    return {
      type: appConstantsPortal.ADD_DELETE_COUNTRIES_RESET,
      clear_data: "",
    };
  }
};

const AdminGetQualifications = (actionPayload: any, isSearch: boolean) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url = !isSearch
        ? `${routes.GET_QUALIFICATIONS}${actionPayload}`
        : `${routes.SEARCH_QUALIFICATION}${actionPayload}`;
      let apiConsume = ApiService.request({
        url,
        http_method: "GET",
        data: null,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return {
      type: appConstantsPortal.GET_HIGHEST_QUALIFICATIONS_PENDING,
      params,
    };
  }
  function success(response: any) {
    return {
      type: appConstantsPortal.GET_HIGHEST_QUALIFICATIONS_SUCCESS,
      response,
    };
  }
  function failure(error: any) {
    return {
      type: appConstantsPortal.GET_HIGHEST_QUALIFICATIONS_FAILURE,
      error,
    };
  }
  function clear() {
    return {
      type: appConstantsPortal.GET_HIGHEST_QUALIFICATIONS_RESET,
      clear_data: "",
    };
  }
};

const AdminAddDeleteQualifications = (actionPayload: any, isAdd: boolean) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url = !isAdd
        ? `${routes.DELETE_QUALIFICATION}${actionPayload}`
        : routes.ADD_QUALIFICATION;
      let http_method = !isAdd ? "DELETE" : "POST";
      let apiConsume = ApiService.request({
        url,
        http_method,
        data: !isAdd ? null : actionPayload,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return {
      type: appConstantsPortal.ADD_DELETE_HIGHEST_QUALIFICATIONS_PENDING,
      params,
    };
  }
  function success(response: any) {
    return {
      type: appConstantsPortal.ADD_DELETE_HIGHEST_QUALIFICATIONS_SUCCESS,
      response,
    };
  }
  function failure(error: any) {
    return {
      type: appConstantsPortal.ADD_DELETE_HIGHEST_QUALIFICATIONS_FAILURE,
      error,
    };
  }
  function clear() {
    return {
      type: appConstantsPortal.ADD_DELETE_HIGHEST_QUALIFICATIONS_RESET,
      clear_data: "",
    };
  }
};

const AdminGetSpecialities = (actionPayload: any, isSearch: boolean) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url = !isSearch
        ? `${routes.GET_SPECIALITIES}${actionPayload}`
        : `${routes.SEARCH_SPECIALITY}${actionPayload}`;
      let apiConsume = ApiService.request({
        url,
        http_method: "GET",
        data: null,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return { type: appConstantsPortal.GET_SPECIALITIES_PENDING, params };
  }
  function success(response: any) {
    return { type: appConstantsPortal.GET_SPECIALITIES_SUCCESS, response };
  }
  function failure(error: any) {
    return { type: appConstantsPortal.GET_SPECIALITIES_FAILURE, error };
  }
  function clear() {
    return { type: appConstantsPortal.GET_SPECIALITIES_RESET, clear_data: "" };
  }
};

const AdminAddDeleteSpecialities = (actionPayload: any, isAdd: boolean) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url = !isAdd
        ? `${routes.DELETE_SPECIALITY}${actionPayload}`
        : routes.ADD_SPECIALITY;
      let http_method = !isAdd ? "DELETE" : "POST";
      let apiConsume = ApiService.request({
        url,
        http_method,
        data: !isAdd ? null : actionPayload,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return { type: appConstantsPortal.ADD_DELETE_SPECIALITIES_PENDING, params };
  }
  function success(response: any) {
    return {
      type: appConstantsPortal.ADD_DELETE_SPECIALITIES_SUCCESS,
      response,
    };
  }
  function failure(error: any) {
    return { type: appConstantsPortal.ADD_DELETE_SPECIALITIES_FAILURE, error };
  }
  function clear() {
    return {
      type: appConstantsPortal.ADD_DELETE_SPECIALITIES_RESET,
      clear_data: "",
    };
  }
};

const AdminSetCurrentSession = (actionPayload: any) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url = routes.SET_CURRENT_SESSION;
      let http_method = "POST";
      let apiConsume = ApiService.request({
        url,
        http_method,
        data: actionPayload,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return { type: appConstantsPortal.SET_CURRENT_SESSION_PENDING, params };
  }
  function success(response: any) {
    return { type: appConstantsPortal.SET_CURRENT_SESSION_SUCCESS, response };
  }
  function failure(error: any) {
    return { type: appConstantsPortal.SET_CURRENT_SESSION_FAILURE, error };
  }
  function clear() {
    return {
      type: appConstantsPortal.SET_CURRENT_SESSION_RESET,
      clear_data: "",
    };
  }
};
const AdminGetCurrentSession = (actionPayload: any) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url = `${routes.GET_ALL_SESSIONS}${actionPayload}`;
      let http_method = "GET";
      let apiConsume = ApiService.request({
        url,
        http_method,
        data: null,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return { type: appConstantsPortal.GET_CURRENT_SESSION_PENDING, params };
  }
  function success(response: any) {
    return { type: appConstantsPortal.GET_CURRENT_SESSION_SUCCESS, response };
  }
  function failure(error: any) {
    return { type: appConstantsPortal.GET_CURRENT_SESSION_FAILURE, error };
  }
  function clear() {
    return {
      type: appConstantsPortal.GET_CURRENT_SESSION_RESET,
      clear_data: "",
    };
  }
};

const AdminResetStudentPasssword = (actionPayload: any, isAdmin?: boolean) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url;
      if (!isAdmin) {
        url = `${routes.RESET_STUDENT_PASSWORD}`;
      } else {
        url = `${routes.RESET_OTHER_USERS_PASSWORD}`;
      }

      let http_method = "POST";
      let apiConsume = ApiService.request({
        url,
        http_method,
        data: actionPayload,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return { type: appConstantsPortal.RESET_STUDENT_PASSWORD_PENDING, params };
  }
  function success(response: any) {
    return {
      type: appConstantsPortal.RESET_STUDENT_PASSWORD_SUCCESS,
      response,
    };
  }
  function failure(error: any) {
    return { type: appConstantsPortal.RESET_STUDENT_PASSWORD_FAILURE, error };
  }
  function clear() {
    return {
      type: appConstantsPortal.RESET_STUDENT_PASSWORD_RESET,
      clear_data: "",
    };
  }
};

const AdminResetPasssword = (actionPayload: any) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url = `${routes.RESET_ADMIN_PASSWORD}`;
      let http_method = "POST";
      let apiConsume = ApiService.request({
        url,
        http_method,
        data: actionPayload,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          let loggedAccountInfo: any = localStorage.getItem("adXAuth") || "{}";
          loggedAccountInfo = JSON.parse(loggedAccountInfo);
          let apiConsume2 = ApiService.request({
            url: `${routes.GET_A_USER}`,
            http_method: "GET",
            data: null,
            headers: { "X-Signature": routes.apiKey },
          });
          dispatch(request(apiConsume2));
          return apiConsume2
            .then((response2: any) => {
              loggedAccountInfo.profile = response2.data;
              localStorage.setItem(
                "adXAuth",
                JSON.stringify(loggedAccountInfo)
              );

              dispatch(success(response));
            })
            .catch((error: any) => {
              dispatch(success(response));
              // dispatch(failure(handleRequestErrors(error)));
            });
          // dispatch(success(response))
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return { type: appConstantsPortal.RESET_ADMIN_PASSWORD_PENDING, params };
  }
  function success(response: any) {
    return { type: appConstantsPortal.RESET_ADMIN_PASSWORD_SUCCESS, response };
  }
  function failure(error: any) {
    return { type: appConstantsPortal.RESET_ADMIN_PASSWORD_FAILURE, error };
  }
  function clear() {
    return {
      type: appConstantsPortal.RESET_ADMIN_PASSWORD_RESET,
      clear_data: "",
    };
  }
};

const AdminUpdateStatus = (actionPayload: any) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url = `${routes.UPDATE_STUDENT_STATUS}`;
      let http_method = "POST";
      let apiConsume = ApiService.request({
        url,
        http_method,
        data: actionPayload,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return { type: appConstantsPortal.UPDATE_A_STUDENT_STATUS_PENDING, params };
  }
  function success(response: any) {
    return {
      type: appConstantsPortal.UPDATE_A_STUDENT_STATUS_SUCCESS,
      response,
    };
  }
  function failure(error: any) {
    return { type: appConstantsPortal.UPDATE_A_STUDENT_STATUS_FAILURE, error };
  }
  function clear() {
    return {
      type: appConstantsPortal.UPDATE_A_STUDENT_STATUS_RESET,
      clear_data: "",
    };
  }
};

const AdminUpdateStudentOrigin = (actionPayload: any) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url = `${routes.UPDATE_STUDENT_ORIGIN}`;
      let http_method = "POST";
      let apiConsume = ApiService.request({
        url,
        http_method,
        data: actionPayload,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return { type: appConstantsPortal.UPDATE_A_STUDENT_ORIGIN_PENDING, params };
  }
  function success(response: any) {
    return {
      type: appConstantsPortal.UPDATE_A_STUDENT_ORIGIN_SUCCESS,
      response,
    };
  }
  function failure(error: any) {
    return { type: appConstantsPortal.UPDATE_A_STUDENT_ORIGIN_FAILURE, error };
  }
  function clear() {
    return {
      type: appConstantsPortal.UPDATE_A_STUDENT_ORIGIN_RESET,
      clear_data: "",
    };
  }
};

const AdminUpdateStudentCourseOfStudy = (actionPayload: any) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url = `${routes.UPDATE_STUDENT_COURSE_OF_STUDY}`;
      let http_method = "PUT";
      let apiConsume = ApiService.request({
        url,
        http_method,
        data: actionPayload,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return {
      type: appConstantsPortal.UPDATE_A_STUDENT_COURSE_OFSTUDY_PENDING,
      params,
    };
  }
  function success(response: any) {
    return {
      type: appConstantsPortal.UPDATE_A_STUDENT_COURSE_OFSTUDY_SUCCESS,
      response,
    };
  }
  function failure(error: any) {
    return {
      type: appConstantsPortal.UPDATE_A_STUDENT_COURSE_OFSTUDY_FAILURE,
      error,
    };
  }
  function clear() {
    return {
      type: appConstantsPortal.UPDATE_A_STUDENT_COURSE_OFSTUDY_RESET,
      clear_data: "",
    };
  }
};

const AdminGetAllMatricNumberHistory = (actionPayload: any) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url = `${routes.STUDENT_PROGRAM_CHANGE_HISTORY}`;
      let http_method = "POST";
      let apiConsume = ApiService.request({
        url,
        http_method,
        data: actionPayload,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return {
      type: appConstantsPortal.GET_STUDENT_PROGRAMCHANGE_HISTORY_PENDING,
      params,
    };
  }
  function success(response: any) {
    return {
      type: appConstantsPortal.GET_STUDENT_PROGRAMCHANGE_HISTORY_SUCCESS,
      response,
    };
  }
  function failure(error: any) {
    return {
      type: appConstantsPortal.GET_STUDENT_PROGRAMCHANGE_HISTORY_FAILURE,
      error,
    };
  }
  function clear() {
    return {
      type: appConstantsPortal.GET_STUDENT_PROGRAMCHANGE_HISTORY_RESET,
      clear_data: "",
    };
  }
};

const AdminCreateAUser = (actionPayload: any) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url = `${routes.CREATE_A_USER}`;
      let http_method = "POST";
      let apiConsume = ApiService.request({
        url,
        http_method,
        data: actionPayload,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return { type: appConstantsPortal.CREATE_A_USER_PENDING, params };
  }
  function success(response: any) {
    return { type: appConstantsPortal.CREATE_A_USER_SUCCESS, response };
  }
  function failure(error: any) {
    return { type: appConstantsPortal.CREATE_A_USER_FAILURE, error };
  }
  function clear() {
    return { type: appConstantsPortal.CREATE_A_USER_RESET, clear_data: "" };
  }
};

const AdminUpdateAUserStatus = (actionPayload: any) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url = `${routes.ACTIVATE_OR_DEACTIVATE_USER}`;
      let http_method = "POST";
      let apiConsume = ApiService.request({
        url,
        http_method,
        data: actionPayload,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return {
      type: appConstantsPortal.ACTIVATE_OR_DEACTIVATE_USER_PENDING,
      params,
    };
  }
  function success(response: any) {
    return {
      type: appConstantsPortal.ACTIVATE_OR_DEACTIVATE_USER_SUCCESS,
      response,
    };
  }
  function failure(error: any) {
    return {
      type: appConstantsPortal.ACTIVATE_OR_DEACTIVATE_USER_FAILURE,
      error,
    };
  }
  function clear() {
    return {
      type: appConstantsPortal.ACTIVATE_OR_DEACTIVATE_USER_RESET,
      clear_data: "",
    };
  }
};

const AdminCreateARole = (actionPayload: any) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url = `${routes.CREATE_A_ROLE}`;
      let http_method = "POST";
      let apiConsume = ApiService.request({
        url,
        http_method,
        data: actionPayload,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return { type: appConstantsPortal.CREATE_A_ROLE_PENDING, params };
  }
  function success(response: any) {
    return { type: appConstantsPortal.CREATE_A_ROLE_SUCCESS, response };
  }
  function failure(error: any) {
    return { type: appConstantsPortal.CREATE_A_ROLE_FAILURE, error };
  }
  function clear() {
    return { type: appConstantsPortal.CREATE_A_ROLE_RESET, clear_data: "" };
  }
};

const AdminAddRemoveUserToARole = (actionPayload: any, actionType: string) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url = "";
      // let url = `${routes.ADD_USER_TO_ROLE}`;

      if (actionType === "remove") {
        // delete actionPayload?.roleName
        url = routes.REMOVE_USER_FROM_ROLE;
      }

      if (actionType === "add") {
        url = routes.ADD_USER_TO_ROLE;
      }
      let http_method = "POST";
      let apiConsume = ApiService.request({
        url,
        http_method,
        data: actionPayload,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return {
      type: appConstantsPortal.UPDATE_A_USER_ROLE_PENDING,
      params,
      actionPayload,
    };
  }
  function success(response: any) {
    return {
      type: appConstantsPortal.UPDATE_A_USER_ROLE_SUCCESS,
      response,
      actionPayload,
    };
  }
  function failure(error: any) {
    return {
      type: appConstantsPortal.UPDATE_A_USER_ROLE_FAILURE,
      error,
      actionPayload,
    };
  }
  function clear() {
    return {
      type: appConstantsPortal.UPDATE_A_USER_ROLE_RESET,
      clear_data: "",
    };
  }
};

const AdminGetAllApis = (actionPayload: any, loadRoles?: boolean) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url = `${routes.ALL_APIS}`;
      let http_method = "GET";
      let apiConsume = ApiService.request({
        url,
        http_method,
        data: null,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          if (loadRoles) {
            let url2 = `${routes.ALL_ROLES}`;
            let http_method = "GET";
            let apiConsume2 = ApiService.request({
              url: url2,
              http_method,
              data: null,
              headers: { "X-Signature": routes.apiKey },
            });
            dispatch(request(apiConsume2));
            return apiConsume2
              .then((response2: any) => {
                let metaData = {
                  roles: response2?.data,
                  apis: response?.data,
                };
                dispatch(success(metaData));
              })
              .catch((error: any) => {
                dispatch(failure(handleRequestErrors(error)));
              });
          } else {
            dispatch(success(response));
          }
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return { type: appConstantsPortal.GET_ALL_APIS_PENDING, params };
  }
  function success(response: any) {
    return { type: appConstantsPortal.GET_ALL_APIS_SUCCESS, response };
  }
  function failure(error: any) {
    return { type: appConstantsPortal.GET_ALL_APIS_FAILURE, error };
  }
  function clear() {
    return { type: appConstantsPortal.GET_ALL_APIS_RESET, clear_data: "" };
  }
};

const AdminGetAllUserRoles = (actionPayload: any) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url = `${routes.ALL_ROLES}`;
      let http_method = "GET";
      let apiConsume = ApiService.request({
        url,
        http_method,
        data: null,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return { type: appConstantsPortal.GET_ALL_USER_ROLES_PENDING, params };
  }
  function success(response: any) {
    return { type: appConstantsPortal.GET_ALL_USER_ROLES_SUCCESS, response };
  }
  function failure(error: any) {
    return { type: appConstantsPortal.GET_ALL_USER_ROLES_FAILURE, error };
  }
  function clear() {
    return {
      type: appConstantsPortal.GET_ALL_USER_ROLES_RESET,
      clear_data: "",
    };
  }
};

const AdminGetAllUsers = (actionPayload: any, isSearch?: boolean) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url = `${routes.GET_USER_PROFILES}${actionPayload}`;

      if (isSearch) {
        url = `${routes.SEARCH_USER_PROFILES}${actionPayload}`;
      }
      let http_method = "GET";
      let apiConsume = ApiService.request({
        url,
        http_method,
        data: null,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          let url = `${routes.ALL_APIS}`;
          let http_method = "GET";
          let apiConsume2 = ApiService.request({
            url,
            http_method,
            data: null,
            headers: { "X-Signature": routes.apiKey },
          });
          dispatch(request(apiConsume2));
          return apiConsume2
            .then((response2: any) => {
              let url2 = `${routes.ALL_ROLES}`;
              let http_method = "GET";
              let apiConsume3 = ApiService.request({
                url: url2,
                http_method,
                data: null,
                headers: { "X-Signature": routes.apiKey },
              });
              dispatch(request(apiConsume3));
              return apiConsume3
                .then((response3: any) => {
                  let metaData = {
                    users: response?.data,
                    roles: response3?.data,
                    apis: response2?.data,
                  };
                  dispatch(success(metaData));
                })
                .catch((error: any) => {
                  dispatch(failure(handleRequestErrors(error)));
                });
            })
            .catch((error: any) => {
              dispatch(failure(handleRequestErrors(error)));
            });
          // dispatch(success(response))
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return { type: appConstantsPortal.GET_ALL_USERS_PENDING, params };
  }
  function success(response: any) {
    return { type: appConstantsPortal.GET_ALL_USERS_SUCCESS, response };
  }
  function failure(error: any) {
    return { type: appConstantsPortal.GET_ALL_USERS_FAILURE, error };
  }
  function clear() {
    return { type: appConstantsPortal.GET_ALL_USERS_RESET, clear_data: "" };
  }
};

const AdminGetAUser = (actionPayload: any, isFetchUser?: boolean) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url = !isFetchUser
        ? routes.GET_A_USER
        : `${routes.GET_A_USER_PROFILE}`;
      let http_method = !isFetchUser ? "GET" : "POST";

      let apiConsume = ApiService.request({
        url,
        http_method,
        data: !isFetchUser ? null : actionPayload,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          if (!isFetchUser) {
            dispatch(success(response));
          } else {
            let apiConsume2 = ApiService.request({
              url: `${routes.GET_ALL_FACULTY_AND_DEPT}`,
              http_method: "GET",
              data: null,
              headers: { "X-Signature": routes.apiKey },
            });
            // dispatch(request(apiConsume2));
            return apiConsume2
              .then((response2: any) => {
                if (response2?.data && response2?.data.length >= 1) {
                  let courseChangeMeta = {
                    data: response?.data,
                    allDeptAndFaculty: response2?.data,
                  };
                  dispatch(success(courseChangeMeta));
                } else {
                  dispatch(failure("No Departments loaded"));
                }
              })
              .catch((error: any) => {
                dispatch(failure(handleRequestErrors(error)));
              });
          }
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return { type: appConstantsPortal.GET_A_USER_PENDING, params };
  }
  function success(response: any) {
    return { type: appConstantsPortal.GET_A_USER_SUCCESS, response };
  }
  function failure(error: any) {
    return { type: appConstantsPortal.GET_A_USER_FAILURE, error };
  }
  function clear() {
    return { type: appConstantsPortal.GET_A_USER_RESET, clear_data: "" };
  }
};

const AdminAssignCourseAdvToDept = (actionPayload: any) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url = `${routes.ASSIGN_COURSE_ADVISOR_TO_DEPT}`;
      let http_method = "POST";
      let apiConsume = ApiService.request({
        url,
        http_method,
        data: actionPayload,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return {
      type: appConstantsPortal.ASSIGN_COURSE_ADVISOR_TO_DEPT_PENDING,
      params,
    };
  }
  function success(response: any) {
    return {
      type: appConstantsPortal.ASSIGN_COURSE_ADVISOR_TO_DEPT_SUCCESS,
      response,
    };
  }
  function failure(error: any) {
    return {
      type: appConstantsPortal.ASSIGN_COURSE_ADVISOR_TO_DEPT_FAILURE,
      error,
    };
  }
  function clear() {
    return {
      type: appConstantsPortal.ASSIGN_COURSE_ADVISOR_TO_DEPT_RESET,
      clear_data: "",
    };
  }
};

const AdminLoadNewUserMeta = (actionPayload: any) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url = `${routes.ALL_APIS}`;
      let http_method = "GET";
      let apiConsume = ApiService.request({
        url,
        http_method,
        data: null,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          let apiConsume2 = ApiService.request({
            url: `${routes.GET_ALL_FACULTY_AND_DEPT}`,
            http_method: "GET",
            data: null,
            headers: { "X-Signature": routes.apiKey },
          });
          // dispatch(request(apiConsume2));
          return apiConsume2
            .then((response2: any) => {
              if (response2?.data && response2?.data.length >= 1) {
                let apiConsume3 = ApiService.request({
                  url: `${routes.GET_ALL_PROGRAMMES}`,
                  http_method: "GET",
                  data: null,
                  headers: { "X-Signature": routes.apiKey },
                });
                // dispatch(request(apiConsume3));
                return apiConsume3
                  .then((response3: any) => {
                    if (response3?.data && response3?.data.length >= 1) {
                      let url2 = `${routes.ALL_ROLES}`;
                      let http_method = "GET";
                      let apiConsume8 = ApiService.request({
                        url: url2,
                        http_method,
                        data: null,
                        headers: { "X-Signature": routes.apiKey },
                      });
                      dispatch(request(apiConsume8));
                      return apiConsume8
                        .then((response8: any) => {
                          let apis: any[] = response?.data;
                          apis = apis.filter(
                            (eachItem) =>
                              eachItem?.description != null &&
                              eachItem?.description !== ""
                          );

                          let metaData = {
                            roles: response8?.data,
                            apis,
                            // apis: response?.data,
                            allDeptAndFaculty: response2?.data,
                            allProgrammes: response3?.data,
                          };
                          dispatch(success(metaData));
                        })
                        .catch((error: any) => {
                          dispatch(failure(handleRequestErrors(error)));
                        });
                    } else {
                      dispatch(failure("No Programmes loaded"));
                    }
                  })
                  .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                  });
              } else {
                dispatch(failure("No Departments loaded"));
              }
            })
            .catch((error: any) => {
              dispatch(failure(handleRequestErrors(error)));
            });
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return { type: appConstantsPortal.NEW_USER_META_PENDING, params };
  }
  function success(response: any) {
    return { type: appConstantsPortal.NEW_USER_META_SUCCESS, response };
  }
  function failure(error: any) {
    return { type: appConstantsPortal.NEW_USER_META_FAILURE, error };
  }
  function clear() {
    return { type: appConstantsPortal.NEW_USER_META_RESET, clear_data: "" };
  }
};

const AdminGetSchoolFeesReport = (actionPayload: any, isSearch?: boolean) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url = `${routes.GET_SCHOOLFEE_PAYMENTS}${actionPayload}`;
      if (isSearch) {
        url = `${routes.SEARCH_SCHOOLFEE_PAYMENTS}${actionPayload}`;
      }
      let http_method = "GET";
      let apiConsume = ApiService.request({
        url,
        http_method,
        data: null,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return {
      type: appConstantsPortal.GET_BURSARY_SCHOOLFEE_REPORTS_PENDING,
      params,
    };
  }
  function success(response: any) {
    return {
      type: appConstantsPortal.GET_BURSARY_SCHOOLFEE_REPORTS_SUCCESS,
      response,
    };
  }
  function failure(error: any) {
    return {
      type: appConstantsPortal.GET_BURSARY_SCHOOLFEE_REPORTS_FAILURE,
      error,
    };
  }
  function clear() {
    return {
      type: appConstantsPortal.GET_BURSARY_SCHOOLFEE_REPORTS_RESET,
      clear_data: "",
    };
  }
};

const AdminDownloadSchoolFeesReport = (actionPayload: any) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url = `${routes.DOWNLOAD_SCHOOLFEE_PAYMENTS}${actionPayload}`;

      let http_method = "GET";
      let apiConsume = ApiService.request({
        url,
        http_method,
        data: null,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return {
      type: appConstantsPortal.DOWNLOAD_BURSARY_SCHOOLFEE_REPORTS_PENDING,
      params,
    };
  }
  function success(response: any) {
    return {
      type: appConstantsPortal.DOWNLOAD_BURSARY_SCHOOLFEE_REPORTS_SUCCESS,
      response,
    };
  }
  function failure(error: any) {
    return {
      type: appConstantsPortal.DOWNLOAD_BURSARY_SCHOOLFEE_REPORTS_FAILURE,
      error,
    };
  }
  function clear() {
    return {
      type: appConstantsPortal.DOWNLOAD_BURSARY_SCHOOLFEE_REPORTS_RESET,
      clear_data: "",
    };
  }
};

const AdminGetAcceptanceFeesReport = (
  actionPayload: any,
  isSearch?: boolean
) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url = `${routes.GET_ACCEPTANCEFEE_PAYMENTS}${actionPayload}`;
      if (isSearch) {
        url = `${routes.SEARCH_ACCEPTANCEFEE_PAYMENTS}${actionPayload}`;
      }
      let http_method = "GET";
      let apiConsume = ApiService.request({
        url,
        http_method,
        data: null,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return {
      type: appConstantsPortal.GET_BURSARY_ACCEPTANCEFEE_REPORTS_PENDING,
      params,
    };
  }
  function success(response: any) {
    return {
      type: appConstantsPortal.GET_BURSARY_ACCEPTANCEFEE_REPORTS_SUCCESS,
      response,
    };
  }
  function failure(error: any) {
    return {
      type: appConstantsPortal.GET_BURSARY_ACCEPTANCEFEE_REPORTS_FAILURE,
      error,
    };
  }
  function clear() {
    return {
      type: appConstantsPortal.GET_BURSARY_ACCEPTANCEFEE_REPORTS_RESET,
      clear_data: "",
    };
  }
};

const AdminDownloadAcceptanceFeesReport = (actionPayload: any) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url = `${routes.DOWNLOAD_ACCEPTANCEFEE_PAYMENTS}${actionPayload}`;

      let http_method = "GET";
      let apiConsume = ApiService.request({
        url,
        http_method,
        data: null,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return {
      type: appConstantsPortal.DOWNLOAD_BURSARY_ACCEPTANCEFEE_REPORTS_PENDING,
      params,
    };
  }
  function success(response: any) {
    return {
      type: appConstantsPortal.DOWNLOAD_BURSARY_ACCEPTANCEFEE_REPORTS_SUCCESS,
      response,
    };
  }
  function failure(error: any) {
    return {
      type: appConstantsPortal.DOWNLOAD_BURSARY_ACCEPTANCEFEE_REPORTS_FAILURE,
      error,
    };
  }
  function clear() {
    return {
      type: appConstantsPortal.DOWNLOAD_BURSARY_ACCEPTANCEFEE_REPORTS_RESET,
      clear_data: "",
    };
  }
};

const AdminGetAdditionalFeesReport = (
  actionPayload: any,
  isSearch?: boolean
) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url = `${routes.GET_ADDITIONALFEE_PAYMENTS}${actionPayload}`;
      if (isSearch) {
        url = `${routes.SEARCH_ADDITIONALFEE_PAYMENTS}${actionPayload}`;
      }
      let http_method = "GET";
      let apiConsume = ApiService.request({
        url,
        http_method,
        data: null,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return {
      type: appConstantsPortal.GET_BURSARY_ADDITIONALFEE_REPORTS_PENDING,
      params,
    };
  }
  function success(response: any) {
    return {
      type: appConstantsPortal.GET_BURSARY_ADDITIONALFEE_REPORTS_SUCCESS,
      response,
    };
  }
  function failure(error: any) {
    return {
      type: appConstantsPortal.GET_BURSARY_ADDITIONALFEE_REPORTS_FAILURE,
      error,
    };
  }
  function clear() {
    return {
      type: appConstantsPortal.GET_BURSARY_ADDITIONALFEE_REPORTS_RESET,
      clear_data: "",
    };
  }
};

const AdminDownloadAdditionalFeesReport = (actionPayload: any) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url = `${routes.DOWNLOAD_ADDITIONALFEE_PAYMENTS}${actionPayload}`;

      let http_method = "GET";
      let apiConsume = ApiService.request({
        url,
        http_method,
        data: null,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return {
      type: appConstantsPortal.DOWNLOAD_BURSARY_ADDITIONALFEE_REPORTS_PENDING,
      params,
    };
  }
  function success(response: any) {
    return {
      type: appConstantsPortal.DOWNLOAD_BURSARY_ADDITIONALFEE_REPORTS_SUCCESS,
      response,
    };
  }
  function failure(error: any) {
    return {
      type: appConstantsPortal.DOWNLOAD_BURSARY_ADDITIONALFEE_REPORTS_FAILURE,
      error,
    };
  }
  function clear() {
    return {
      type: appConstantsPortal.DOWNLOAD_BURSARY_ADDITIONALFEE_REPORTS_RESET,
      clear_data: "",
    };
  }
};

const AdminGetMatriculationReport = (
  actionPayload: any,
  isSearch?: boolean,
  isOld?: boolean,
  isOldSearch?: boolean,
) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url;

      if (isOld) {
        url = `${routes.OLD_MATRICULATION_REPORT}${actionPayload}`;
      } else {
        url = `${routes.GET_MATRICULATION_REPORT}${actionPayload}`;
      }

      if (isSearch) {
        url = `${routes.SEARCH_MATRICULATION_REPORT}${actionPayload}`;
      }

      if(isOldSearch){
        url = `${routes.SEARCH_OLD_MATRICULATION_REPORT}${actionPayload}`;
      }

      
      let http_method = "GET";
      let apiConsume = ApiService.request({
        url,
        http_method,
        data: null,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return {
      type: appConstantsPortal.GET_MATRICULATION_REPORTS_PENDING,
      params,
    };
  }
  function success(response: any) {
    return {
      type: appConstantsPortal.GET_MATRICULATION_REPORTS_SUCCESS,
      response,
    };
  }
  function failure(error: any) {
    return {
      type: appConstantsPortal.GET_MATRICULATION_REPORTS_FAILURE,
      error,
    };
  }
  function clear() {
    return {
      type: appConstantsPortal.GET_MATRICULATION_REPORTS_RESET,
      clear_data: "",
    };
  }
};

const AdminDownloadMatriculationReport = (actionPayload: any, isOld?: boolean,) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url = `${routes.DOWNLOAD_MATRICULATION_REPORT}${actionPayload}`;

      if (isOld) {
        url = `${routes.DOWNLOAD_OLD_MATRICULATION_REPORT}${actionPayload}`;
      } else {
        url = `${routes.DOWNLOAD_MATRICULATION_REPORT}${actionPayload}`;
      }

      let http_method = "GET";
      let apiConsume = ApiService.request({
        url,
        http_method,
        data: null,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return {
      type: appConstantsPortal.DOWNLOAD_MATRICULATION_REPORT_PENDING,
      params,
    };
  }
  function success(response: any) {
    return {
      type: appConstantsPortal.DOWNLOAD_MATRICULATION_REPORT_SUCCESS,
      response,
    };
  }
  function failure(error: any) {
    return {
      type: appConstantsPortal.DOWNLOAD_MATRICULATION_REPORT_FAILURE,
      error,
    };
  }
  function clear() {
    return {
      type: appConstantsPortal.DOWNLOAD_MATRICULATION_REPORT_RESET,
      clear_data: "",
    };
  }
};

const AdminGetNewStudentsPaymentsReport = (
  actionPayload: any,
  isSearch?: boolean
) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url = `${routes.GET_NEW_STUDENTS_PAYMENTS_REPORT}${actionPayload}`;
      if (isSearch) {
        url = `${routes.SEARCH_NEW_STUDENTS_PAYMENTS_REPORT}${actionPayload}`;
      }
      let http_method = "GET";
      let apiConsume = ApiService.request({
        url,
        http_method,
        data: null,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return {
      type: appConstantsPortal.GET_NEW_STUDENTS_PAYMENTS_REPORTS_PENDING,
      params,
    };
  }
  function success(response: any) {
    return {
      type: appConstantsPortal.GET_NEW_STUDENTS_PAYMENTS_REPORTS_SUCCESS,
      response,
    };
  }
  function failure(error: any) {
    return {
      type: appConstantsPortal.GET_NEW_STUDENTS_PAYMENTS_REPORTS_FAILURE,
      error,
    };
  }
  function clear() {
    return {
      type: appConstantsPortal.GET_NEW_STUDENTS_PAYMENTS_REPORTS_RESET,
      clear_data: "",
    };
  }
};

const AdminDownloadNewStudentsPaymentsReport = (actionPayload: any) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url = `${routes.DOWNLOAD_NEW_STUDENTS_PAYMENTS_REPORT}${actionPayload}`;

      let http_method = "GET";
      let apiConsume = ApiService.request({
        url,
        http_method,
        data: null,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return {
      type: appConstantsPortal.DOWNLOAD_NEW_STUDENTS_PAYMENTS_REPORTS_PENDING,
      params,
    };
  }
  function success(response: any) {
    return {
      type: appConstantsPortal.DOWNLOAD_NEW_STUDENTS_PAYMENTS_REPORTS_SUCCESS,
      response,
    };
  }
  function failure(error: any) {
    return {
      type: appConstantsPortal.DOWNLOAD_NEW_STUDENTS_PAYMENTS_REPORTS_FAILURE,
      error,
    };
  }
  function clear() {
    return {
      type: appConstantsPortal.DOWNLOAD_NEW_STUDENTS_PAYMENTS_REPORTS_RESET,
      clear_data: "",
    };
  }
};

const AdminGetStudentsRegistrationReport = (
  actionPayload: any,
  isSearch?: boolean
) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url = `${routes.GET_STUDENTS_REGISTRATION_REPORT}${actionPayload}`;
      if (isSearch) {
        url = `${routes.SEARCH_STUDENTS_REGISTRATION_REPORT}${actionPayload}`;
      }
      let http_method = "GET";
      let apiConsume = ApiService.request({
        url,
        http_method,
        data: null,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return {
      type: appConstantsPortal.GET_STUDENTS_REGISTRATION_REPORTS_PENDING,
      params,
    };
  }
  function success(response: any) {
    return {
      type: appConstantsPortal.GET_STUDENTS_REGISTRATION_REPORTS_SUCCESS,
      response,
    };
  }
  function failure(error: any) {
    return {
      type: appConstantsPortal.GET_STUDENTS_REGISTRATION_REPORTS_FAILURE,
      error,
    };
  }
  function clear() {
    return {
      type: appConstantsPortal.GET_STUDENTS_REGISTRATION_REPORTS_RESET,
      clear_data: "",
    };
  }
};

const AdminDownloadStudentsRegistrationReport = (actionPayload: any) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url = `${routes.DOWNLOAD_STUDENTS_REGISTRATION_REPORT}${actionPayload}`;
      let http_method = "GET";
      let apiConsume = ApiService.request({
        url,
        http_method,
        data: null,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return {
      type: appConstantsPortal.DOWNLOAD_STUDENTS_REGISTRATION_REPORT_PENDING,
      params,
    };
  }
  function success(response: any) {
    return {
      type: appConstantsPortal.DOWNLOAD_STUDENTS_REGISTRATION_REPORT_SUCCESS,
      response,
    };
  }
  function failure(error: any) {
    return {
      type: appConstantsPortal.DOWNLOAD_STUDENTS_REGISTRATION_REPORT_FAILURE,
      error,
    };
  }
  function clear() {
    return {
      type: appConstantsPortal.DOWNLOAD_STUDENTS_REGISTRATION_REPORT_RESET,
      clear_data: "",
    };
  }
};

const AdminGetNelFund = (actionPayload: any, isSearch?: boolean) => {
  return (dispatch: Dispatch) => {
    let url = `${routes.GET_NELFUND_REPORT}${actionPayload}`;
    if (isSearch) {
      url = `${routes.GET_NELFUND_REPORT_SEARCH}${actionPayload}`;
    }
    let http_method = "GET";
    let apiConsume = ApiService.request({
      url,
      http_method,
      data: null,
      headers: { "X-Signature": routes.apiKey },
    });
    dispatch(request(apiConsume));
    return apiConsume
      .then((response: any) => {
        dispatch(success(response));
      })
      .catch((error: any) => {
        dispatch(failure(handleRequestErrors(error)));
      });
  };

  function request(params: any) {
    return {
      type: appConstantsPortal.GET_NELFUND_REPORTS_PENDING,
      params,
    };
  }
  function success(response: any) {
    return {
      type: appConstantsPortal.GET_NELFUND_REPORTS_SUCCESS,
      response,
    };
  }
  function failure(error: any) {
    return {
      type: appConstantsPortal.GET_NELFUND_REPORTS_FAILURE,
      error,
    };
  }
};

const AdminDownloadNelfundReport = (actionPayload: any) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url = `${routes.GET_NELFUND_REPORT_DOWNLOAD}${actionPayload}`;
      let http_method = "GET";
      let apiConsume = ApiService.request({
        url,
        http_method,
        data: null,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }
  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return {
      type: appConstantsPortal.GET_NELFUND_DOWNLOAD_REPORTS_PENDING,
      params,
    };
  }
  function success(response: any) {
    return {
      type: appConstantsPortal.GET_NELFUND_DOWNLOAD_REPORTS_SUCCESS,
      response,
    };
  }
  function failure(error: any) {
    return {
      type: appConstantsPortal.GET_NELFUND_DOWNLOAD_REPORTS_FAILURE,
      error,
    };
  }
  function clear() {
    return {
      type: appConstantsPortal.GET_NELFUND_DOWNLOAD_REPORTS_RESET,
      clear_data: "",
    };
  }
};

const PrintBioData = (Payload: any) => {
  return (dispatch: Dispatch) => {
    let apiConsume = ApiService.request({
      url: `${routes.PRINT_BIODATA}${Payload}`,
      http_method: "GET",
      data: null,
      headers: { "X-Signature": routes.apiKey },
    })

    return apiConsume
      .then((response: any) => {
        dispatch(success(response));
      })
      .catch((error: any) => {
        dispatch(failure(handleRequestErrors(error)));
      });
  };

  function success(response: any) {
    return { type: appConstantsPortal.PRINT_BIODATA_SUCCESS, response };
  }
  function failure(error: any) {
    return { type: appConstantsPortal.PRINT_BIODATA_FAILURE, error };
  }

};




const AdminGetEORegistrationReport = (
  actionPayload: any,
  isSearch?: boolean
) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url = `${routes.GET_EO_REGISTRATION_REPORT}${actionPayload}`;
      if (isSearch) {
        url = `${routes.SEARCH_EO_REGISTRATION_REPORT}${actionPayload}`;
      }
      let http_method = "GET";
      let apiConsume = ApiService.request({
        url,
        http_method,
        data: null,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };



  function request(params: any) {
    return {
      type: appConstantsPortal.GET_EO_REGISTRATION_REPORTS_PENDING,
      params,
    };
  }
  function success(response: any) {
    return {
      type: appConstantsPortal.GET_EO_REGISTRATION_REPORTS_SUCCESS,
      response,
    };
  }
  function failure(error: any) {
    return {
      type: appConstantsPortal.GET_EO_REGISTRATION_REPORTS_FAILURE,
      error,
    };
  }
  function clear() {
    return {
      type: appConstantsPortal.GET_EO_REGISTRATION_REPORTS_RESET,
      clear_data: "",
    };
  }


}


const AdminDownloadEORegistrationReport = (actionPayload: any) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let url = `${routes.DOWNLOAD_EO_REGISTRATION_REPORT}${actionPayload}`;
      let http_method = "GET";
      let apiConsume = ApiService.request({
        url,
        http_method,
        data: null,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          dispatch(success(response));
        })
        .catch((error: any) => {
          dispatch(failure(handleRequestErrors(error)));
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return {
      type: appConstantsPortal.DOWNLOAD_EO_REGISTRATION_REPORTS_PENDING,
      params,
    };
  }
  function success(response: any) {
    return {
      type: appConstantsPortal.DOWNLOAD_EO_REGISTRATION_REPORTS_SUCCESS,
      response,
    };
  }
  function failure(error: any) {
    return {
      type: appConstantsPortal.DOWNLOAD_EO_REGISTRATION_REPORTS_FAILURE,
      error,
    };
  }
  function clear() {
    return {
      type: appConstantsPortal.DOWNLOAD_EO_REGISTRATION_REPORTS_RESET,
      clear_data: "",
    };
  }
};






export const adminInAppActions = {
  AdminLoadDashboard,
  AdminLoadCourseRegMeta,
  AdminLoadPresetCourseReg,
  AdminSavePresetCourseReg,
  AdminDeletePresetCourseReg,
  AdminGetStudentsList,
  AdminUpdateAStudent,
  AdminGetAStudent,
  AdminLoadBiodataMeta,
  adminSearchStudents,
  AdminAssignCourseAdvToDept,

  AdminGetAStudentInvoice,
  AdminUpdateAStudentInvoice,

  AdminGetAwardsInView,
  AdminAddDeleteAwardInView,

  AdminGetCountries,
  AdminAddDeleteCountry,

  AdminGetQualifications,
  AdminAddDeleteQualifications,

  AdminGetSpecialities,
  AdminAddDeleteSpecialities,

  AdminSetCurrentSession,
  AdminGetCurrentSession,

  AdminResetStudentPasssword,
  AdminResetPasssword,
  AdminUpdateStatus,
  AdminUpdateStudentOrigin,
  AdminUpdateStudentCourseOfStudy,
  AdminGetAllMatricNumberHistory,

  AdminCreateAUser,
  AdminUpdateAUserStatus,
  AdminCreateARole,
  AdminAddRemoveUserToARole,
  AdminGetAllApis,
  AdminGetAllUserRoles,
  AdminGetAllUsers,
  AdminGetAUser,

  AdminLoadNewUserMeta,

  AdminGetSchoolFeesReport,
  AdminDownloadSchoolFeesReport,
  AdminGetAcceptanceFeesReport,
  AdminDownloadAcceptanceFeesReport,
  AdminGetAdditionalFeesReport,
  AdminDownloadAdditionalFeesReport,

  AdminGetMatriculationReport,
  AdminDownloadMatriculationReport,
  AdminGetNewStudentsPaymentsReport,
  AdminDownloadNewStudentsPaymentsReport,
  AdminGetStudentsRegistrationReport,
  AdminDownloadStudentsRegistrationReport,
  AdminGetNelFund,
  AdminDownloadNelfundReport,
  PrintBioData,
  AdminGetEORegistrationReport,
  AdminDownloadEORegistrationReport
};
